import "../styles/Footer.scss";
import { useHistory } from "react-router-dom";
const Footer = () => {
    const history = useHistory();
    return (
        <footer style={{"marginTop": "15%"}}>
            <div className="footer-contents-wrap">
                <div className="footer-contents">
                    <div className="footer-contents-title">학생회</div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/introduction')}>학생회 소개</div>
                    </div>
                    <div className="footer-contents-item">
                    <div className="footer-menu" onClick={() => history.push('/rules')}>회식 / 세칙</div>
                        
                    </div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/committee')}>생운위 / 전학대회</div>
                    </div>
                    <div className="footer-contents-item">
                    <div className="footer-menu" onClick={() => history.push('/minutes')}>집행부 회의록</div>
                    </div>
                </div>
                <div className="footer-contents">
                    <div className="footer-contents-title">과별 게시판</div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/consumer')}>소비자학과</div>
                    </div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/nutrition')}>식품영양학과</div>
                    </div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/child')}>아동가족학과</div>
                    </div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/clothing')}>의류학과</div>
                    </div>
                </div>
                <div className="footer-contents">
                    <div className="footer-contents-title">
                        <div className="footer-menu" onClick={() => history.push('/notices')}>공지사항</div>
                    </div>
                </div>
                <div className="footer-contents">
                    <div className="footer-contents-title">
                        <div className="footer-menu" onClick={() => history.push('/complaints')}>민원 / 건의사항</div>
                    </div>
                </div>
                <div className="footer-contents">
                    <div className="footer-contents-title">커뮤니티</div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/market')}>장터 게시판</div>
                    </div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/advertisements')}>홍보 게시판</div>
                    </div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/free')}>자유 게시판</div>
                    </div>
                    <div className="footer-contents-item">
                        <div className="footer-menu" onClick={() => history.push('/study')}>스터디 / 강의 게시판</div>
                    </div>
                </div>
            </div>
            <div className="footer-belt">
                서울대학교 생활과학대학 학생회 <span>|</span> 서울특별시 관악구
                관악로1 서울대학교 222동 B112호
                <span>|</span> snuhumec2021@gmail.com
            </div>
        </footer>
    );
};

export default Footer;
