import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { dbService, storageService } from "fbase";
import Pagination from "../components/Pagination";
import searchIcon from "../Asset/searchIcon.png";
import important from "../Asset/important.png";

const Minutes = ({ isLoggedIn, userObj }) => {
    const history = useHistory();
    // const [importantMinutes, setImportantMinutes] = useState([]);

    const [minutes, setMinutes] = useState([]);
    const [filteredMinutes, setFilteredMinutes] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const limit = 20;
    const [page, setPage] = useState(1);
    const offset = (page - 1) * limit;

    const getRealDate = (t) => {
        const d = new Date(t);
        return (
            d.getFullYear() +
            "/" +
            (d.getMonth() + 1) +
            "/" +
            d.getDate() +
            " " +
            d.getHours() +
            ":" +
            d.getMinutes()
        );
    };

    const searchText = useRef();

    const onSearch = (e) => {
        const text = searchText.current.value;
        const filteredInfo = [];
        minutes.forEach((minute) => {
            if (
                minute.content.indexOf(text) !== -1 ||
                minute.title.indexOf(text) !== -1
            ) {
                filteredInfo.push(minute);
            }
        });
        const filteredImportantArray = filteredInfo.filter(
            (e) => e.isImportant
        );
        const filteredMinorArray = filteredInfo.filter((e) => !e.isImportant);

        setFilteredMinutes([...filteredImportantArray, ...filteredMinorArray]);
        setIsFiltered(true);

    };
    const onSearchEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSearch();
        }
    };

    useEffect(() => {
        dbService.collection("집행부").onSnapshot((snapshot) => {
            const minuteArray = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const importantMinutesArray = minuteArray.filter(
                (e) => e.isImportant
            );
            importantMinutesArray.sort((x, y) => y.createdAt - x.createdAt);

            const minorMinutesArray = minuteArray.filter((e) => !e.isImportant);
            minorMinutesArray.sort((x, y) => y.createdAt - x.createdAt);

            setMinutes([...importantMinutesArray, ...minorMinutesArray]);
        });
        // setIsFiltered(false);
    }, []);

    
    return (
        <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
            <div className="mainContent">
                <div className="title-banner">학생회</div>

                <div className="post-wrap">
                    <div className="post-menu">
                        <div className="post-menu-item">
                            <Link to="/introduction">학생회 소개</Link>
                        </div>
                        <div id="menu-clothing" className="post-menu-item">
                            <Link to="/rules">회칙 / 세칙</Link>
                        </div>
                        <div id="menu-consumer" className="post-menu-item">
                            <Link to="/committee">생운위 / 전학대회</Link>
                        </div>
                        <div id="menu-child" className="post-menu-item">
                            <Link to="/minutes">집행부 회의록</Link>
                        </div>
                    </div>

                    <div className="post-contents-wrap">
                        <div className="post-name">집행부 회의록</div>
                        <div className="post-contents">
                            <div className="post-list">
                                <div className="post-list-head">
                                    <div className="post-list-title">제목</div>
                                    <div className="post-list-createdAt">
                                        게시일
                                    </div>
                                    <div className="post-list-author">
                                        작성자
                                    </div>
                                </div>
                                {isFiltered ? (
                                    <div className="post-list-main">
                                        {filteredMinutes
                                            // .sort((a, b) => {
                                            //     return b.createdAt - a.createdAt;
                                            // })
                                            .slice(offset, offset + limit)
                                            .map((minute) => {
                                                return (
                                                    <div
                                                        className="post-list-main-element"
                                                        id={
                                                            minute.isImportant
                                                                ? "important"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            history.push(
                                                                `/minute_detail/${minute.id}`
                                                            )
                                                        }
                                                    >
                                                        {minute.isImportant ? (
                                                            <img
                                                                src={important}
                                                                alt="중요"
                                                                style={{
                                                                    maxWidth:
                                                                        "16px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: "16px",
                                                                }}
                                                            ></div>
                                                        )}

                                                        <div
                                                            className="post-list-main-title"
                                                            style={{
                                                                paddingLeft:
                                                                    "16px",
                                                            }}
                                                        >
                                                            {minute.title}
                                                        </div>
                                                        <div className="post-list-main-createdAt">
                                                            {getRealDate(
                                                                minute.createdAt
                                                            )}
                                                        </div>
                                                        <div className="post-list-main-author">
                                                            {minute.author}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                ) : (
                                    <div className="post-list-main">
                                        {minutes
                                            // .sort((a, b) => {
                                            //     return b.createdAt - a.createdAt;
                                            // })

                                            .slice(offset, offset + limit)
                                            .map((minute) => {
                                                return (
                                                    <div
                                                        className="post-list-main-element"
                                                        id={
                                                            minute.isImportant
                                                                ? "important"
                                                                : ""
                                                        }
                                                        onClick={() =>
                                                            history.push(
                                                                `/minute_detail/${minute.id}`
                                                            )
                                                        }
                                                    >
                                                        {minute.isImportant ? (
                                                            <img
                                                                src={important}
                                                                alt="중요"
                                                                style={{
                                                                    maxWidth:
                                                                        "16px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: "16px",
                                                                }}
                                                            ></div>
                                                        )}

                                                        <div
                                                            className="post-list-main-title"
                                                            style={{
                                                                paddingLeft:
                                                                    "16px",
                                                            }}
                                                        >
                                                            {minute.title}
                                                        </div>
                                                        <div className="post-list-main-createdAt">
                                                            {getRealDate(
                                                                minute.createdAt
                                                            )}
                                                        </div>
                                                        <div className="post-list-main-author">
                                                            {minute.author}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}

                                <div
                                    className="baseNav"
                                    style={{ display: "flex" }}
                                >
                                    <div
                                        className="post-list-pagination"
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            "flexDirection": "row-reverse",
                                            marginRight: "20%",
                                        }}
                                    >
                                        {isFiltered ? 
                                        <Pagination
                                            total={filteredMinutes.length}
                                            limit={limit}
                                            page={page}
                                            setPage={setPage}
                                        /> : 
                                        <Pagination
                                            total={minutes.length}
                                            limit={limit}
                                            page={page}
                                            setPage={setPage} 
                                        />}
                                    </div>
                                    <div></div>
                                    <div
                                        style={{
                                            display: "flex",
                                            "align-items": "center",
                                        }}
                                    >
                                        <form
                                            className="searchForm"
                                            style={{
                                                border: "1px solid black",
                                                width: "240px",
                                                height: "32px",
                                            }}
                                        >
                                            <input
                                                className="text"
                                                name="searchBtn"
                                                placeholder="검색어를 입력하세요"
                                                autoComplete="off"
                                                ref={searchText}
                                                style={{
                                                    width: "200px",
                                                    height: "27px",
                                                    border: "none",
                                                    paddingLeft: "4px",
                                                }}
                                                onKeyDown={onSearchEnter}
                                            />
                                            <img
                                                src={searchIcon}
                                                alt="검색"
                                                style={{
                                                    maxWidth: "5.5%",
                                                    marginLeft: "1%",
                                                    "paddingTop": "8px",
                                                }}
                                                onClick={onSearch}
                                            />
                                        </form>
                                        {isLoggedIn ? (
                                            <button
                                                onClick={() =>
                                                    history.push(
                                                        "create_minute"
                                                    )
                                                }
                                                style={{
                                                    width: "100px",
                                                    height: "30px",
                                                    marginLeft: "3%",
                                                    backgroundColor: "#1D333E",
                                                    color: "white",
                                                }}
                                            >
                                                글쓰기
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Minutes;
