import React, { useEffect } from "react";
import { authService, dbService } from "fbase";
import { useHistory } from "react-router-dom";

export default ({ userObj }) => {
    const history = useHistory();
    const onLogOutClick = () => {
        authService.signOut();
        history.push("/");
    };

    const getMyNweets = async () => {
        const nweets = await dbService
            .collection("시험용 게시글")
            .where("creatorId", "==", userObj.uid)
            .orderBy("createdAt")
            .get();
    };

    useEffect(() => {
        getMyNweets();
    }, []);

    return (
        <>
            <span>여기는 프로필 페이지입니다</span>
            <button onClick={onLogOutClick}>Log Out</button>
        </>
    );
};
