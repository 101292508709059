import Layout from "../components/Layout";
import React, { Component, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { dbService, storageService } from "fbase";
import { doc, getDoc, collection, query, where } from "firebase/firestore";
import UpdateMinute from "../pages/UpdateMinute";
import "../styles/postPageBase.scss";
import "../styles/DetailPage.scss";

const MinuteDetail = ({ isLoggedIn, userObj }) => {
    let { id } = useParams();
    const [init, setInit] = useState(false);
    const [comments, setComments] = useState([]);
    const [commentInit, setCommentInit] = useState(false);
    const [thisMinute, setThisMinute] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [photos, setPhotos] = useState([]);
    const history = useHistory();
    const [commentContent, setCommentContent] = useState("");
    const [userInit, setUserInit] = useState(false);

    const onCommentChange = (event) => {
        const {
            target: { name, value },
        } = event;
        if (name === "commentContent") {
            setCommentContent(value);
        }
    };

    const onCommentSubmit = async (event) => {
        event.preventDefault();
        const commentObj = {
            content: commentContent,
            postId: thisMinute.id,
            postType: "집행부",
            createdAt: Date.now(),
            creatorId: userObj.uid,
            author: userObj.displayName,
        };
        const res = await dbService.collection("집행부댓글").add(commentObj);
        // console.log(res.id);
        setCommentContent("");
        history.push();
    };

    useEffect(() => {
        dbService.collection("집행부").onSnapshot((snapshot) => {
            snapshot.docs.map((doc) => {
                if (doc.id === id) {
                    setThisMinute({
                        id: doc.id,
                        ...doc.data(),
                    });

                    setInit(true);
                }
            });
        });
    }, []);

    useEffect(() => {
        let commentsArray = [];
        if (init) {
            dbService.collection("집행부댓글").onSnapshot((snapshot) => {
                commentsArray = snapshot.docs
                    .filter((comment) => {
                        if (comment.data().postId == thisMinute.id) return true;
                    })
                    .map((c) => ({
                        id: c.id,
                        ...c.data(),
                    }));
                setComments(commentsArray);
            });
            setCommentInit(true);
        }
    }, [init]);

    useEffect(() => {
        if (userObj) {
            setUserInit(true);
        }
    }, [userObj]);

    const onImportantClick = async () => {
        await dbService.doc(`집행부/${thisMinute.id}`).update({
            isImportant: true,
        });
        history.push("/minutes");
    };

    const onNotImportantClick = async () => {
        await dbService.doc(`집행부/${thisMinute.id}`).update({
            isImportant: false,
        });
        history.push("/minutes");
    };

    const getRealDate = (t) => {
        const d = new Date(t);
        return (
            d.getFullYear() +
            "/" +
            (d.getMonth() + 1) +
            "/" +
            d.getDate() +
            " " +
            d.getHours() +
            ":" +
            d.getMinutes()
        );
    };

    const onDeleteClick = async () => {
        const ok = window.confirm(
            "게시물을 정말 삭제하시겠습니까? 삭제 후에는 복구할 수 없습니다."
        );

        if (ok) {
            await dbService.doc(`집행부/${thisMinute.id}`).delete();
            // 사진 삭제하는 부분인데 안돼서 보류합니다!!
            // if (thisMinute.photoUrl !== "") {
            //     await storageService
            //         .refFromURL(thisMinute.photoUrl[0])
            //         .delete();
            // }
        }
        history.push("/minutes");
    };

    const onCommentDeleteClick = async (commentId) => {
        const ok = window.confirm(
            "댓글을 정말 삭제하시겠습니까? 삭제 후에는 복구할 수 없습니다."
        );

        if (ok) {
            await dbService.doc(`집행부댓글/${commentId}`).delete();
        }
        history.push();
    };

    if (!isEditing) {
        return (
            <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
                {init ? (
                    <div className="mainContent">
                        <div className="title-banner">집행부 회의록</div>
                        <div className="post-wrap">
                            <div className="post-contents-wrap">
                                <div className="post-name">집행부 회의록</div>
                                <div className="post-contents">
                                    <div className="post-contents-title">
                                        {thisMinute.title}
                                    </div>
                                    <div className="post-contents-info">
                                        <div className="post-contents-info-author">
                                            작성자 : {thisMinute.author}
                                        </div>
                                        <div className="post-contents-info-date">
                                            작성일 :{" "}
                                            {getRealDate(thisMinute.createdAt)}
                                        </div>
                                        <div className="post-contents-info-views">
                                            조회수
                                        </div>
                                    </div>
                                    <div className="post-contents-content-label"></div>
                                    <div className="post-contents-content">
                                        {thisMinute.content}
                                    </div>

                                    {isLoggedIn ? (
                                        thisMinute.creatorId === userObj.uid ? (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        setIsEditing(true)
                                                    }
                                                    style={{
                                                        width: "100px",
                                                        height: "30px",
                                                        marginRight: "3%",
                                                        backgroundColor:
                                                            "#1D333E",
                                                        color: "white",
                                                        marginBottom: "3%",
                                                    }}
                                                >
                                                    수정하기
                                                </button>
                                                <button
                                                    style={{
                                                        width: "100px",
                                                        height: "30px",
                                                        marginRight: "3%",
                                                        backgroundColor:
                                                            "#1D333E",
                                                        color: "white",
                                                        marginBottom: "3%",
                                                    }}
                                                    onClick={onDeleteClick}
                                                >
                                                    삭제하기
                                                </button>
                                            </>
                                        ) : null
                                    ) : null}
                                    {isLoggedIn &&
                                    !thisMinute.isImportant &&
                                    process.env.REACT_APP_ADMIN_ID ==
                                        userObj.uid ? (
                                        <button
                                            style={{
                                                width: "150px",
                                                height: "30px",
                                                marginRight: "3%",
                                                backgroundColor: "#1D333E",
                                                color: "white",
                                                marginBottom: "3%",
                                            }}
                                            onClick={onImportantClick}
                                        >
                                            상단공지로 등록하기
                                        </button>
                                    ) : null}
                                    {isLoggedIn &&
                                    thisMinute.isImportant &&
                                    process.env.REACT_APP_ADMIN_ID ==
                                        userObj.uid ? (
                                        <button
                                            style={{
                                                width: "150px",
                                                height: "30px",
                                                marginRight: "3%",
                                                backgroundColor: "#1D333E",
                                                color: "white",
                                                marginBottom: "3%",
                                            }}
                                            onClick={onNotImportantClick}
                                        >
                                            상단공지에서 내리기
                                        </button>
                                    ) : null}

                                    {thisMinute.photoUrl && (
                                        <div>
                                            {thisMinute.photoUrl.map((e) => {
                                                return (
                                                    <div>
                                                        <img
                                                            className="post-photos"
                                                            src={e}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                    <div className="comments-section">
                                        <div className="comments-section-label">
                                            댓글
                                        </div>

                                        {commentInit
                                            ? comments
                                                  .sort(
                                                      (a, b) =>
                                                          a.createdAt -
                                                          b.createdAt
                                                  )
                                                  .map((c) => (
                                                      <div className="real-comment">
                                                          <div className="real-comment-author-and-createAt">
                                                              <div className="real-comment-author">
                                                                  {c.author}
                                                              </div>
                                                              <div className="real-comment-createdAt">
                                                                  {getRealDate(
                                                                      c.createdAt
                                                                  )}
                                                              </div>
                                                          </div>
                                                          <div className="real-comment-content">
                                                              {c.content}
                                                          </div>
                                                          {userInit &&
                                                          c.creatorId ===
                                                              userObj.uid ? (
                                                              <button
                                                                  className="comment-delete-button"
                                                                  style={{
                                                                      width: "100px",
                                                                      height: "30px",
                                                                      marginRight:
                                                                          "3%",
                                                                      backgroundColor:
                                                                          "gray",
                                                                      color: "white",
                                                                      border: "0px",
                                                                  }}
                                                                  onClick={() =>
                                                                      onCommentDeleteClick(
                                                                          c.id
                                                                      )
                                                                  }
                                                              >
                                                                  댓글삭제
                                                              </button>
                                                          ) : null}
                                                      </div>
                                                  ))
                                            : null}
                                        {/* <div className="real-comment">
                                            여기에 댓글이 달립니다
                                        </div>
                                        <div className="real-comment">
                                            여기에 댓글이 달립니다
                                        </div>
                                        <div className="real-comment">
                                            여기에 댓글이 달립니다
                                        </div> */}
                                        <div className="comment-form-wrap">
                                            <form onSubmit={onCommentSubmit}>
                                                <div className="comments-section-main">
                                                    <div className="create-comment">
                                                        <textarea
                                                            className="create-comment-input"
                                                            value={
                                                                commentContent
                                                            }
                                                            type="text"
                                                            name="commentContent"
                                                            onChange={
                                                                onCommentChange
                                                            }
                                                            placeholder="댓글을 작성하세요"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="create-comment-button">
                                                        <input
                                                            type="submit"
                                                            value="작성하기"
                                                            style={{
                                                                // width: "100px",
                                                                height: "30px",
                                                                width: "100%",
                                                                backgroundColor:
                                                                    "#1D333E",
                                                                color: "white",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </Layout>
        );
    } else {
        return (
            <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
                <UpdateMinute
                    isLoggedIn={isLoggedIn}
                    userObj={userObj}
                    editId={thisMinute.id}
                    editTitle={thisMinute.title}
                    editContent={thisMinute.content}
                    editPhotoUrl={thisMinute.photoUrl}
                />
            </Layout>
        );
    }
};

export default MinuteDetail;
