import React, { Children, useState } from "react";
import {
    HashRouter as Router,
    Routes,
    Route,
    Switch,
    Redirect,
    BrowserRouter,
} from "react-router-dom";
import Auth from "../routes/Auth";
import Home from "../pages/Home";
import Profile from "routes/Profile";
import Navigation from "components/Navigation";
import Consumer from "../pages/Consumer";
import Child from "../pages/Child";
import Clothing from "../pages/Clothing";
import FindPassword from "../pages/FindPassword";
import Nutrition from "../pages/Nutrition";
import Rules from "../pages/Rules";
import Introduction from "../pages/Introduction";
import Committee from "../pages/Committee";
import Minutes from "../pages/Minutes";
import Notices from "../pages/Notices";
import NoticeDetail from "../pages/NoticeDetail";
import EventDetail from "../pages/EventDetail";
import Complaints from "../pages/Complaints";
import Market from "../pages/Market";
import Search from "../pages/Search";
import CreateEvent from "../pages/CreateEvent";
import UpdateEvent from "../pages/UpdateEvent";
import Advertisements from "../pages/Advertisements";
import Free from "../pages/Free";
import Study from "../pages/Study";
import LogIn from "../pages/LogIn";
import SignUp from "../pages/SignUp";
import MyPage from "../pages/MyPage";
import CreateNotice from "../pages/CreateNotice";
import UpdateNotice from "../pages/UpdateNotice";
import CreateRule from "../pages/CreateRule";
import UpdateRule from "../pages/UpdateRule";
import RuleDetail from "../pages/RuleDetail";
import CreateCommittee from "../pages/CreateCommittee";
import UpdateCommittee from "../pages/UpdateCommittee";
import CommitteeDetail from "../pages/CommitteeDetail";
import CreateMinute from "../pages/CreateMinute";
import UpdateMinute from "../pages/UpdateMinute";
import MinuteDetail from "../pages/MinuteDetail";
import CreateConsumer from "../pages/CreateConsumer";
import UpdateConsumer from "../pages/UpdateConsumer";
import ConsumerDetail from "../pages/ConsumerDetail";
import CreateNutrition from "../pages/CreateNutrition";
import UpdateNutrition from "../pages/UpdateNutrition";
import NutritionDetail from "../pages/NutritionDetail";
import CreateChild from "../pages/CreateChild";
import UpdateChild from "../pages/UpdateChild";
import ChildDetail from "../pages/ChildDetail";
import CreateClothing from "../pages/CreateClothing";
import UpdateClothing from "../pages/UpdateClothing";
import ClothingDetail from "../pages/ClothingDetail";
import CreateComplaint from "../pages/CreateComplaint";
import UpdateComplaint from "../pages/UpdateComplaint";
import ComplaintDetail from "../pages/ComplaintDetail";
import CreateMarket from "../pages/CreateMarket";
import UpdateMarket from "../pages/UpdateMarket";
import MarketDetail from "../pages/MarketDetail";
import CreateAdvertisement from "../pages/CreateAdvertisement";
import UpdateAdvertisement from "../pages/UpdateAdvertisement";
import AdvertisementDetail from "../pages/AdvertisementDetail";
import CreateFree from "../pages/CreateFree";
import UpdateFree from "../pages/UpdateFree";
import FreeDetail from "../pages/FreeDetail";
import CreateStudy from "../pages/CreateStudy";
import UpdateStudy from "../pages/UpdateStudy";
import StudyDetail from "../pages/StudyDetail";
import MoreMyPage from "../pages/MoreMyPage";
const AppRouter = ({ isLoggedIn, userObj, setUserObj }) => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                        setUserObj={setUserObj}
                    />
                </Route>
                <Route exact path="/profile">
                    <Profile isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/auth">
                    <Auth isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/introduction">
                    <Introduction isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/moreInfo/:id">
                    <MoreMyPage isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/rules">
                    <Rules isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/rule_detail/:id">
                    <RuleDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_rule">
                    <CreateRule isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_event">
                    <CreateEvent isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/search">
                    <Search isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/committee">
                    <Committee isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/committee_detail/:id">
                    <CommitteeDetail
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>
                <Route exact path="/create_committee">
                    <CreateCommittee
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>

                <Route exact path="/minutes">
                    <Minutes isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/minute_detail/:id">
                    <MinuteDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_minute">
                    <CreateMinute isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/consumer">
                    <Consumer isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/consumer_detail/:id">
                    <ConsumerDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_consumer">
                    <CreateConsumer isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/child">
                    <Child isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/child_detail/:id">
                    <ChildDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_child">
                    <CreateChild isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/nutrition">
                    <Nutrition isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/nutrition_detail/:id">
                    <NutritionDetail
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>
                <Route exact path="/create_nutrition">
                    <CreateNutrition
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>

                <Route exact path="/clothing">
                    <Clothing isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/clothing_detail/:id">
                    <ClothingDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_clothing">
                    <CreateClothing isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                {/* <Route
                    path="/notices"
                    element={
                        <Notices isLoggedIn={isLoggedIn} userObj={userObj} />
                    }
                >
                    <Route
                        path="/create"
                        element={
                            <CreateNotice
                                isLoggedIn={isLoggedIn}
                                userObj={userObj}
                            />
                        }
                    />
                </Route> */}
                <Route exact path="/notices">
                    <Notices isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/notice_detail/:id">
                    <NoticeDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/update_event/:id">
                    <UpdateEvent isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/create_notice">
                    <CreateNotice isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/lostPw">
                    <FindPassword isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/complaints">
                    <Complaints isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/complaint_detail/:id">
                    <ComplaintDetail
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>
                <Route exact path="/create_complaint">
                    <CreateComplaint
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>

                <Route exact path="/market">
                    <Market isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/market_detail/:id">
                    <MarketDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_market">
                    <CreateMarket isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/advertisements">
                    <Advertisements isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/advertisement_detail/:id">
                    <AdvertisementDetail
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>
                <Route exact path="/create_advertisement">
                    <CreateAdvertisement
                        isLoggedIn={isLoggedIn}
                        userObj={userObj}
                    />
                </Route>

                <Route exact path="/free">
                    <Free isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/free_detail/:id">
                    <FreeDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_free">
                    <CreateFree isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/study">
                    <Study isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/study_detail/:id">
                    <StudyDetail isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/create_study">
                    <CreateStudy isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>

                <Route exact path="/login">
                    <LogIn isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/signup">
                    <SignUp isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
                <Route exact path="/mypage">
                    <MyPage isLoggedIn={isLoggedIn} userObj={userObj} />
                </Route>
            </Switch>
        </Router>
    );
};

export default AppRouter;
