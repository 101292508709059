import Layout from "../components/Layout";
import React, { useState, useEffect, useRef } from "react";
import { authService, dbService } from "fbase";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../styles/Search.scss";

const Search = ({ isLoggedIn, userObj }) => {
    const [classification, setClassification] = useState("0");
    const [criterion, setCriterion] = useState("0");
    const [isSearched, setIsSearched] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [notices, setNotices] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [major, setMajor] = useState([]);
    const [studentsCouncil, setStudentsCouncil] = useState([]);
    
    const [filteredNotices, setFilteredNotices] = useState([]);
    const [filteredCommunities, setFilteredCommunities] = useState([]);
    const [filteredMajor, setFilteredMajor] = useState([]);
    const [filteredStudentsCouncil, setFilteredStudentsCouncil] = useState([]);
    const history = useHistory();
    
    useEffect(() => {
        setFilteredStudentsCouncil(studentsCouncil.filter(e => e.title.indexOf(keyword) !== -1 || e.content.indexOf(keyword) !== -1));
    }, [studentsCouncil])
    useEffect(() => {
        setFilteredCommunities(communities.filter(e => e.title.indexOf(keyword) !== -1 || e.content.indexOf(keyword) !== -1));
    }, [communities])
    useEffect(() => {
        setFilteredMajor(major.filter(e => e.title.indexOf(keyword) !== -1 || e.content.indexOf(keyword) !== -1));
    }, [major])
    useEffect(() => {
        setFilteredNotices(notices.filter(e => e.title.indexOf(keyword) !== -1 || e.content.indexOf(keyword) !== -1));
    }, [notices])

    const onClickMore = (e) => {
        e.preventDefault();
        const more = e.target.className
        
            // pathname: "/set_account",
            // state: {userCell: userCell}
          
        if (more === "moreMajorSentence"){
            history.push({
                pathname : "/moreInfo/major",
                state : {filteredMajor}
            })
        }   else if (more === "moreCommunitySentence"){
            history.push({
                pathname : "/moreInfo/community",
                state : {filteredCommunities}
            })
        }   else if (more === "moreCommitteeSentence"){
            history.push({
                pathname : "/moreInfo/committee",
                state : {filteredStudentsCouncil}
            })
        }   else if (more === "moreNoticeSentence"){
            history.push({
                pathname : "/moreInfo/notice",
                state : {filteredNotices}
            })
        }
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (e.target.filter.value === "") {
            return;
        }
        setKeyword(e.target.filter.value);
        setIsSearched(true);
        if (classification === "-1" || classification === "0"){
            setStudentsCouncil([]);
            setMajor([]);
            setCommunities([]);
            setNotices([]);
            dbService.collection("학생회").onSnapshot((snapshot) => {
                const council = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setStudentsCouncil((prev) => [...prev,...council]);
            });

            
            

            dbService.collection("회칙").onSnapshot((snapshot) => {
                const rules = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setStudentsCouncil((prev) => [...prev, ...rules]);
            })

            dbService.collection("생운위").onSnapshot((snapshot) => {
                const committee = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setStudentsCouncil((prev) => [...prev, ...committee]);
            })

            dbService.collection("집행부").onSnapshot((snapshot) => {
                const exec = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setStudentsCouncil((prev) => [...prev, ...exec]);
            })

            dbService.collection("소비자학과").onSnapshot((snapshot) => {
                const consumer = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMajor((prev) => [...prev, ...consumer]);
            })
            dbService.collection("식품영양학과").onSnapshot((snapshot) => {
                const food = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMajor((prev) => [...prev, ...food]);
            })
            dbService.collection("아동가족학과").onSnapshot((snapshot) => {
                const child = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMajor((prev) => [...prev, ...child]);
            })

            dbService.collection("의류학과").onSnapshot((snapshot) => {
                const fashion = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setMajor((prev) => [...prev, ...fashion]);
            })


            dbService.collection("공지사항").onSnapshot((snapshot) => {
                const notice = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setNotices((prev) => [...prev, ...notice]);
            })

            dbService.collection("장터").onSnapshot((snapshot) => {
                const market = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                
                setCommunities((prev) => [...prev, ...market]);
            })
            dbService.collection("홍보").onSnapshot((snapshot) => {
                const advertise = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setCommunities((prev) => [...prev, ...advertise]);
            })

            dbService.collection("자유").onSnapshot((snapshot) => {
                const free = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCommunities((prev) => [...prev, ...free]);
            })

            dbService.collection("스터디").onSnapshot((snapshot) => {
                const study = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCommunities((prev) => [...prev, ...study]);
            })

            // 전체 다 db에서
        } else if (classification === "1") {
            dbService.collection("학생회").onSnapshot((snapshot) => {
                const council = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setStudentsCouncil((prev) => [...prev, ...council]);
            })
            dbService.collection("회칙").onSnapshot((snapshot) => {
                const rules = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setStudentsCouncil((prev) => [...prev, ...rules]);
            })

            dbService.collection("생운위").onSnapshot((snapshot) => {
                const committee = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setStudentsCouncil((prev) => [...prev, ...committee]);
            })

            dbService.collection("집행부").onSnapshot((snapshot) => {
                const exec = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setStudentsCouncil((prev) => [...prev, ...exec]);
            });
        }   else if (classification === "2"){
            dbService.collection("소비자학과").onSnapshot((snapshot) => {
                const consumer = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMajor((prev) => [...prev, ...consumer]);
            })

            dbService.collection("식품영양학과").onSnapshot((snapshot) => {
                const food = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMajor((prev) => [...prev, ...food]);
            })

            dbService.collection("아동가족학과").onSnapshot((snapshot) => {
                const child = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMajor((prev) => [...prev, ...child]);
            })
            dbService.collection("의류학과").onSnapshot((snapshot) => {
                const fashion = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMajor((prev) => [...prev, ...fashion]);
            });
        }   else if (classification === "3"){

            dbService.collection("공지사항").onSnapshot((snapshot) => {
                const _notices = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setNotices((prev) => [...prev, ..._notices]);
            })
        }   else if (classification === "4"){

            // 커뮤니티
            dbService.collection("장터").onSnapshot((snapshot) => {
                const market = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCommunities((prev) => [...prev, ...market]);
            })

            dbService.collection("홍보").onSnapshot((snapshot) => {
                const advertise = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCommunities((prev) => [...prev, ...advertise]);
            })

            dbService.collection("자유").onSnapshot((snapshot) => {
                const free = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCommunities((prev) => [...prev, ...free]);
            })
            dbService.collection("스터디").onSnapshot((snapshot) => {
                const study = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCommunities((prev) => [...prev, ...study]);
            })
        }   

        // const [notices, setNotices] = useState([]);
        // const [communities, setCommunities] = useState([]);
        // const [major, setMajor] = useState([]);
        // const [studentsCouncil, setStudentsCouncil] = useState([]);
        if (criterion === "0") {
            setStudentsCouncil((prev) => [...prev.filter((e) => e.content.indexOf(keyword) !== -1 || e.title.indexOf(keyword) !== -1)]);
            setCommunities((prev) => [...prev.filter((e) => e.content.indexOf(keyword) !== -1 || e.title.indexOf(keyword) !== -1)]);
            setMajor((prev) => [...prev.filter((e) => e.content.indexOf(keyword) !== -1 || e.title.indexOf(keyword) !== -1)]);
            setStudentsCouncil((prev) => [...prev.filter((e) => e.content.indexOf(keyword) !== -1 || e.title.indexOf(keyword) !== -1)])
            // 제목 + 내용
        }   else if (criterion === "1"){
                setNotices((prev) => [[...prev].filter(e => e.title.indexOf(keyword) !== -1)]);
                setCommunities((prev) => [prev.filter(e => e.title.indexOf(keyword) !== -1)]);
                setMajor((prev) => [prev.filter(e => e.title.indexOf(keyword) !== -1)]);
                setStudentsCouncil((prev) => [prev.filter(e => e.title.indexOf(keyword) !== -1)]);
            // 제목
        } else if (criterion === "2") {
            setStudentsCouncil((prev) => [prev.filter((e) => e.content.indexOf(keyword) !== -1)]);
            setCommunities((prev) => [prev.filter((e) => e.content.indexOf(keyword) !== -1)]);
            setMajor((prev) => [prev.filter((e) => e.content.indexOf(keyword) !== -1)]);
            setStudentsCouncil((prev) => [prev.filter((e) => e.content.indexOf(keyword) !== -1)]
            );
            // 내용
        }
    };
    

    const onClassificationChange = (e) => {
        setClassification(e.target.value); // notice;
    };
    const onCriterionChange = (e) => {
        setCriterion(e.target.value);
    };

    return (
        <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
            <div
                style={{
                    zIndex: "-1",
                    display: "flex",
                    alignItems: "center",
                    width: "95%",
                    height: "20vh",
                    marginTop: "10%",
                    backgroundColor: "#CACAA4",
                    marginLeft: "2.5%",
                    opacity: "60%",
                }}
            >
                <select
                    name="classification"
                    style={{ height: "25%", width: "12%", marginLeft: "26%" }}
                    onChange={onClassificationChange}
                >
                    <option value="-1">분류</option>
                    <option value="0">전체</option>
                    <option value="1">학생회</option>
                    <option value="2">과별 게시판</option>
                    <option value="3">공지사항</option>
                    <option value="4">커뮤니티</option>
                </select>
                <select
                    name="criterion"
                    style={{ height: "25%", width: "12%", marginLeft: "1%" }}
                    onChange={onCriterionChange}
                >
                    <option value="0">제목+내용</option>
                    <option value="1">제목</option>
                    <option value="2">내용</option>
                </select>
                <form onSubmit={onSubmit} style={{ display: "flex" }}>
                    <input
                        type="text"
                        name="filter"
                        style={{
                            width: "300px",
                            height: "38px",
                            marginLeft: "1%",
                        }}
                    />
                    <button
                        id="searchSubmitBtn"
                        style={{ width: "70px", marginLeft: "1%"}}
                        // onClick={onSearchClick}
                        type="submit"
                    >
                        검색
                    </button>
                </form>
            </div>
            {isSearched ? (
                <div
                    style={{
                        marginTop: "2%",
                        marginLeft: "43%",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <span style={{ fontWeight: "bold", fontSize: "125%" }}>
                        {keyword}
                    </span>
                    <span style={{ fontSize: "125%", marginLeft: "1%" }}>
                        전체검색 결과
                    </span>
                    <div
                        style={{
                            width: "60px",
                            height: "30px",
                            backgroundColor: "#CACAA4",
                            opacity: "60%",
                            marginLeft: "3%",
                        }}
                    ></div>
                </div>
            ) : null}
            {isSearched && filteredCommunities && filteredMajor && filteredNotices && filteredStudentsCouncil ? <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "9%",
                            marginTop: "3%",
                        }}
                    >
                        <span style={{ marginLeft: "3%" }}>학생회 게시판</span>
                        <div
                            style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#CACAA4",
                                marginLeft: "1%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {filteredStudentsCouncil.length}
                        </div>
                        <span style={{ marginLeft: "3%" }}>과별 게시판</span>
                        <div
                            style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#CACAA4",
                                marginLeft: "1%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {filteredMajor.length}
                        </div>
                        <span style={{ marginLeft: "3%" }}>공지사항</span>
                        <div
                            style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#CACAA4",
                                marginLeft: "1%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {filteredNotices.length}
                        </div>
                        <span style={{ marginLeft: "3%" }}>커뮤니티</span>
                        <div
                            style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#CACAA4",
                                marginLeft: "1%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {filteredCommunities.length}
                        </div>
                    </div> : null}
            {isSearched && filteredCommunities && filteredMajor && filteredNotices && filteredStudentsCouncil ? (
                classification === "-1" || classification === "0" ? (
                    <><>
                        <div id="myCommitteeSection">
                            <div style={{"display" : "flex", "width" : "30%", "alignItems" : "center"}}>
                                <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>학생회 게시판 내 결과</div>
                                <div style={{width: "30px", height: "30px", borderRadius: "15px", backgroundColor: "#CACAA4", marginTop : "7px", marginLeft: "5%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    {filteredStudentsCouncil.length}
                                </div>
                            </div>
                            
                            {filteredStudentsCouncil.length > 0 ? filteredStudentsCouncil.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((committee) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${committee.from}_detail/${committee.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{committee.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{committee.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(committee.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{committee.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div> }
                            <div className="moreCommitteeSentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>  
                    </>
                    <>    
                        <div id="myMajorSection">
                            <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>과별 게시판 내 결과</div>
                            {filteredMajor.length > 0 ? filteredMajor.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((e) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${e.from}_detail/${e.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(e.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{e.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div>}
                            <div className="moreMajorSentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>
                    </>
                    <>    
                        <div id="myNoticeSection">
                            <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>공지사항 내 결과</div>
                            {filteredNotices.length > 0 ? filteredNotices.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((e) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${e.from}_detail/${e.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(e.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{e.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div>}
                            <div className="moreNoticeSentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>
                    </>
                    <>    
                        <div id="myCommunitySection">
                            <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>커뮤니티 게시판 내 결과</div>
                            {filteredCommunities.length > 0 ? filteredCommunities.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((e) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${e.from}_detail/${e.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(e.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{e.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div>}
                            <div className="moreCommunitySentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>
                    </></>
                ) : classification === "1" ? (
                    <>
                        <div id="myCommitteeSection">
                            <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>학생회 게시판 내 결과</div>
                            {filteredStudentsCouncil.length > 0 ? filteredStudentsCouncil.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((committee) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${committee.from}_detail/${committee.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{committee.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{committee.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(committee.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{committee.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div>}
                            <div className="moreCommitteeSentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>
                    </>
                ) : classification === "2" ? (
                    <>    
                        <div id="myMajorSection">
                            <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>과별 게시판 내 결과</div>
                            {filteredMajor.length > 0 ? filteredMajor.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((e) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${e.from}_detail/${e.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(e.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{e.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div>}
                            <div className="moreMajorSentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>
                    </>
                ) : classification === "3" ? (
                    <>    
                        <div id="myNoticeSection">
                            <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>공지사항 내 결과</div>
                            {filteredNotices.length > 0 ? filteredNotices.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((e) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${e.from}_detail/${e.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(e.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{e.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div>}
                            <div className="moreNoticeSentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>
                    </>
                ) : classification === "4" ? (
                    <>    
                        <div id="myCommunitySection">
                            <div style={{"marginTop" : "3%", "fontSize" : "150%"}}>커뮤니티 게시판 내 결과</div>
                            {filteredCommunities.length > 0 ? filteredCommunities.sort((a,b) => b.createdAt - a.createdAt).slice(0, 3).map((e) => {
                                return  (
                                    <>
                                        <div onClick={() => history.push(`/${e.from}_detail/${e.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.author}</div>
                                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{e.title}</div>
                                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(e.createdAt).toLocaleDateString('en-US')}</div>
                                        </div>
                                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{e.content}</div>    
                                    </>
                                )
                            }
                            ) : <div style={{"marginLeft" : "3%", "fontWeight" : "bold", "fontSize" : "160%"}}>검색 결과가 없습니다.</div>}
                            <div className="moreCommunitySentence" style={{"marginLeft" : "90%", "display":"flex", "justifyContent": "center", "alignItems":"center",  "width" : "80px", "height" : "50px", "backgroundColor" : "#DFDFC8", "fontSize" : "120%"}} onClick={onClickMore}>더 보기</div>
                        </div>
                    </>
                ) : null
            ) : null}
        </Layout>
    );
};
export default Search;
