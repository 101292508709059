import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { dbService, storageService } from "fbase";
import "../styles/CreatePostPage.scss";

const CreateClothing = ({ isLoggedIn, userObj }) => {
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [photos, setPhotos] = useState([]);
    const [fileName, setFileName] = useState("첨부파일");
    const [fileExceeded, setFileExceeded] = useState(false);
    const onChange = (event) => {
        const {
            target: { name, value },
        } = event;
        if (name === "title") {
            setTitle(value);
        } else if (name === "content") {
            setContent(value);
        }
    };

    const onClearPhoto = (photo) => {
        setPhotos(photos.filter((e) => e !== photo.target.value));
        setFileExceeded(false);
        
    };

    const onFileChange = async (event) => {
        const {
            target: { files },
        } = event;

        if (files.length > 0) {
            setFileName(files[files.length - 1].name);
        } else {
            setFileName("첨부파일");
        }

        
        let fileURLs = [...photos];

        for (let i = 0; i < files.length; i++) {
            const theFile = files[i];
            const reader = new FileReader();

            reader.onload = () => {
                fileURLs.push(reader.result);
                setPhotos([...fileURLs]);
            };
            reader.readAsDataURL(theFile);
            // reader.onloadend = (finishedEvent) => {
            //     const {
            //         currentTarget: { result },
            //     } = finishedEvent;
            // };
            // await reader.readAsDataURL(theFile);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        
        // return;

        // let photoUrl = "";
        // if (photo !== "") {
        //     const photoRef = storageService
        //         .ref()
        //         .child(`${userObj.uid}/${uuidv4()}`);
        //     const response = await photoRef.putString(photo, "data_url");
        //     photoUrl = await response.ref.getDownloadURL();
        // }
        
        try {
            const clothingObj = {
                title,
                content,
                createdAt: Date.now(),
                creatorId: userObj.uid,
                photoUrl: photos,
                author: userObj.displayName,
                isImportant: false,
                from: "clothing",
            };

            const res = await dbService.collection("의류학과").add(clothingObj);
            
            setTitle("");
            setContent("");
            setPhotos([]);
            history.push("/clothing");
        } catch (err) {
            
            setFileExceeded(true);
            return;
        }
    };

    return (
        <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
            <div className="title-banner">의류학과</div>
            <div className="post-wrap">
                <div className="post-contents-wrap">
                    <div className="post-name">의류학과</div>
                    <form onSubmit={onSubmit}>
                        <div className="create-post-title">
                            <input
                                className="create-post-title-input"
                                value={title}
                                type="text"
                                name="title"
                                onChange={onChange}
                                placeholder="제목을 작성하세요"
                                maxlength="30"
                                required
                            />
                        </div>
                        <div className="create-post-content">
                            <textarea
                                className="create-post-content-input"
                                value={content}
                                type="text"
                                name="content"
                                onChange={onChange}
                                placeholder="내용을 작성하세요"
                                required
                            />
                        </div>

                        <div className="create-post-uploads">
                            <input
                                className="upload-name"
                                value={fileName}
                                placeholder={fileName}
                            />
                            <label for="upload-file">사진 찾기</label>
                            <input
                                id="upload-file"
                                type="file"
                                accept="image/*"
                                onChange={onFileChange}
                                multiple
                            />
                        </div>
                        <div className="create-post-submit">
                            <input
                                type="submit"
                                value="작성하기"
                                style={{
                                    width: "100px",
                                    height: "30px",
                                    backgroundColor: "#1D333E",
                                    color: "white",
                                }}
                            />
                        </div>
                    </form>
                    {fileExceeded ? (
                        <div style={{ color: "red", marginBottom: "2%" }}>
                            *사진 첨부 파일 용량이 초과되었습니다. 1MB 이하로
                            크기를 조정해주세요.
                        </div>
                    ) : null}
                    {photos.length !== 0 &&
                        photos.map((e) => (
                            <div className="create-post-photo-section">
                                <div className="create-post-photo-section-photo-box">
                                    <img
                                        className="create-post-photo-section-real-photo"
                                        src={e}
                                    ></img>
                                </div>
                                <div className="create-post-photo-section-photo-delete">
                                    <button
                                        onClick={(e) => onClearPhoto(e)}
                                        value={e}
                                        style={{
                                            display: "inline-block",
                                            width: "100px",
                                            height: "30px",
                                            color: "#fff",
                                            verticalAlign: "middle",
                                            backgroundColor: "#999999",
                                            cursor: "pointer",
                                            marginTop: "1%",
                                            border: "0px",
                                        }}
                                    >
                                        사진 삭제
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </Layout>
    );
};

export default CreateClothing;
