import Layout from "../components/Layout";
import React, { useState, useEffect, useRef } from "react";
import { authService, dbService } from "fbase";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import "../styles/Search.scss";

const MoreMyPage = ({ isLoggedIn, userObj }) => {
    const history = useHistory();
    const location = useLocation();
    const {id} = useParams();
    const [userInit, setUserInit] = useState(false);
    const [info, setInfo] = useState([]);
    const [filter, setFilter] = useState("");
    useEffect(() => {
        if(userObj){
            setUserInit(true);
        }
    }, [userObj]);
    useEffect(() => {
        if (id === "major"){
            setInfo(location.state.filteredMajor)
            setFilter("과별 게시판 내 결과")
        }   else if (id === "committee"){
            setInfo(location.state.filteredCommittee);
            setFilter("학생회 게시판 내 결과")
        }   else if (id === "community"){
            setInfo(location.state.filteredCommunity);
            setFilter("커뮤니티 게시판 내 결과");
        }   else if (id === "notice"){
            setInfo(location.state.filteredNotices);
            setFilter("공지사항 게시판 내 결과")
        }   else if (id === "filteredComm");

        
    }, [])

    
    
    
    return userInit && info && filter && (
        <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5%",
                    marginTop: "3%",
                }}>
                <span style={{ marginLeft: "1%", "fontSize" : "140%" }}>{filter}</span>
                <div
                    style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "15px",
                        backgroundColor: "#CACAA4",
                        marginLeft: "1%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {info.length}
                </div>
            </div>
            {info.sort((a,b) => b.createdAt - a.createdAt).map((committee) => {
                return  (
                    <div style={{"width" : "90%", "marginLeft" : "5%"}}>
                        <div onClick={() => history.push(`/${committee.from}_detail/${committee.id}`)} style={{"display" : "flex", "marginTop" : "2%", "backgroundColor" : "#DFDFC8", "height" : "8%"}}>
                            <div style={{"width" : "15%", "display" : "flex", "justifyContent" : "space-between", "alignItems" : "center", "paddingLeft" : "3%"}}>{committee.author}</div>
                            <div style={{"width" : "73%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{committee.title}</div>
                            <div style={{"width" : "12%", "display" : "flex", "justifyContent" : "flex-start", "alignItems" : "center", "paddingLeft" : "3%"}}>{new Date(committee.createdAt).toLocaleDateString('en-US')}</div>
                        </div>
                        <div style={{"marginLeft" : "1.5%", "marginBottom" : "1.5%", "marginTop": "2%"}}>{committee.content}</div>    
                    </div>
                )
            }
            )}
        </Layout>
    )
}

export default MoreMyPage;