import Layout from "../components/Layout";
import React, { useState, useEffect } from "react";
import { authService, dbService } from "fbase";
import { Link, useHistory, useLocation } from "react-router-dom";
import login from "../Asset/login.png"
import "../styles/LogIn.scss";

const LogIn = ({ isLoggedIn, userObj }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [newAccount, setNewAccount] = useState(true);
    const [error, setError] = useState("");
    const [isSnuMail, setIsSnuMail] = useState(true);
    const [isSamePassword, setIsSamePassword] = useState(true);

    const history = useHistory();

    const checkSnuMail = (mail) => {
        setIsSnuMail(mail.endsWith("@snu.ac.kr"));
    };

    const onChange = (event) => {
        const {
            target: { name, value },
        } = event;
        if (name === "email") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        checkSnuMail(email);
        if (!isSnuMail) {
            return;
        }
        try {
            let data;
            data = await authService.signInWithEmailAndPassword(
                email,
                password
            );
            history.push("/");
        } catch (error) {
            setError(error.message);
        }
    };

    // const toggleAccount = () => setNewAccount((prev) => !prev);

    return (
        <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
            <img src={login} alt="로그인 폼" style={{"maxWidth" : "13%", "marginTop" : "5%", "marginLeft" : "5%"}}/>
            <div className="loginForm">
                
                <form onSubmit={onSubmit}>
                    <div id="idDiv">
                        <div>ID</div> 
                        <input
                            id="idInput"
                            type="text"
                            name="email"
                            placeholder="Email"
                            required
                            value={email}
                            onChange={onChange}
                        />
                    </div>
                    {isSnuMail ? null : (
                        <div id="waringId" style={{ "marginLeft" : "29%", "marginRight" : "32%", "color" : "red", "marginTop" : "0.5%" }}>*올바른 SNU메일을 입력해주세요.</div>
                    )}
                    <div id="pwDiv">
                        <div>비밀번호</div>
                        <input
                            id="pwInput"
                            type="password"
                            name="password"
                            placeholder="Password"
                            required
                            value={password}
                            onChange={onChange}
                        />
                    </div>
                    {error === "" ? null : <div style={{ "marginLeft" : "29%", "marginRight" : "32%", "color" : "red", "marginTop" : "0.5%" }}>*비밀번호가 잘못되었습니다.</div>}
                    <div>
                        <input id="loginSubmitBtn"type="submit" value="로그인" />
                    </div>
                    <div id="loginControl" style={{"display" : "flex", "fontSize" : "135%", "marginLeft" : "42.5%", "marginRight" : "32%", "marginTop" : "3%"}}>
                        <div id="navFindPw" onClick={()=> history.push('/lostPw')}>비밀번호 찾기</div>
                        &nbsp;/&nbsp;
                        <div id="navSignup" onClick={() => history.push('/signup')}>회원가입</div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default LogIn;
