import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { dbService, storageService } from "fbase";
import Pagination from "../components/Pagination";
import searchIcon from "../Asset/searchIcon.png";
import important from "../Asset/important.png";
import marketDefaultImg from "../Asset/marketDefaultImg.png";
import gallery from "../Asset/gallery.svg";
import list from "../Asset/list.svg";
import "../styles/Market.scss";

const Market = ({ isLoggedIn, userObj }) => {
    const history = useHistory();
    // const [importantMarkets, setImportantMarkets] = useState([]);

    const [markets, setMarkets] = useState([]);
    const [filteredMarkets, setFilteredMarkets] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const limit = 20;
    const [page, setPage] = useState(1);
    const offset = (page - 1) * limit;
    const [isListDisplay, setIsListDisplay] = useState(true);

    const onListDisplayClick = () => {
        setIsListDisplay(true);
    };

    const onGalleryDisplayClick = () => {
        setIsListDisplay(false);
    };

    const getRealDate = (t) => {
        const d = new Date(t);
        return (
            d.getFullYear() +
            "/" +
            (d.getMonth() + 1) +
            "/" +
            d.getDate() +
            " " +
            d.getHours() +
            ":" +
            d.getMinutes()
        );
    };

    const searchText = useRef();

    const onSearch = (e) => {
        const text = searchText.current.value;
        const filteredInfo = [];
        markets.forEach((market) => {
            if (
                market.content.indexOf(text) !== -1 ||
                market.title.indexOf(text) !== -1
            ) {
                filteredInfo.push(market);
            }
        });

        const filteredImportantArray = filteredInfo.filter(
            (e) => e.isImportant
        );
        const filteredMinorArray = filteredInfo.filter((e) => !e.isImportant);

        setFilteredMarkets([...filteredImportantArray, ...filteredMinorArray]);
        setIsFiltered(true);
    };

    const onSearchEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSearch();
        }
    };

    useEffect(() => {
        dbService.collection("장터").onSnapshot((snapshot) => {
            const marketArray = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            const importantMarketsArray = marketArray.filter(
                (e) => e.isImportant
            );
            importantMarketsArray.sort((x, y) => y.createdAt - x.createdAt);

            const minorMarketsArray = marketArray.filter((e) => !e.isImportant);
            minorMarketsArray.sort((x, y) => y.createdAt - x.createdAt);

            setMarkets([...importantMarketsArray, ...minorMarketsArray]);
        });
        // setIsFiltered(false);
    }, []);

    return (
        <Layout isLoggedIn={isLoggedIn} userObj={userObj}>
            <div className="mainContent">
                <div className="title-banner">커뮤니티</div>

                <div className="post-wrap">
                    <div className="post-menu">
                        <div className="post-menu-item">
                            <Link to="/market">장터 게시판</Link>
                        </div>
                        <div id="menu-promotion" className="post-menu-item">
                            <Link to="/advertisements">홍보 게시판</Link>
                        </div>
                        <div id="menu-free" className="post-menu-item">
                            <Link to="/free">자유 게시판</Link>
                        </div>
                        <div id="menu-study" className="post-menu-item">
                            <Link to="/study">스터디/강의 게시판</Link>
                        </div>
                    </div>

                    <div className="post-contents-wrap">
                        <div className="post-name">장터 게시판</div>
                        <div className="post-contents">
                            <div className="post-list">
                                {isListDisplay ? (
                                    <div className="post-list-head">
                                        <div className="post-list-title">
                                            제목
                                        </div>
                                        <div className="post-list-createdAt">
                                            게시일
                                        </div>
                                        <div className="post-list-author">
                                            <div className="post-list-author-text">
                                                작성자
                                            </div>
                                            <div className="post-list-author-img">
                                                <img
                                                    onClick={
                                                        onGalleryDisplayClick
                                                    }
                                                    src={gallery}
                                                />
                                                <img
                                                    onClick={onListDisplayClick}
                                                    src={list}
                                                    style={{
                                                        marginLeft: "5px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="post-list-head">
                                        <div className="post-list-head-market">
                                            <img
                                                onClick={onGalleryDisplayClick}
                                                src={gallery}
                                            />
                                            <img
                                                onClick={onListDisplayClick}
                                                src={list}
                                                style={{
                                                    marginLeft: "5px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {/* 여기가 조건부 렌더링 시작입니다!! */}

                                {isListDisplay ? (
                                    <>
                                        {isFiltered ? (
                                            <div className="post-list-main">
                                                {filteredMarkets
                                                    // .sort((a, b) => {
                                                    //     return b.createdAt - a.createdAt;
                                                    // })
                                                    .slice(
                                                        offset,
                                                        offset + limit
                                                    )
                                                    .map((market) => {
                                                        return (
                                                            <div
                                                                className="post-list-main-element"
                                                                id={
                                                                    market.isImportant
                                                                        ? "important"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/market_detail/${market.id}`
                                                                    )
                                                                }
                                                            >
                                                                {market.isImportant ? (
                                                                    <img
                                                                        src={
                                                                            important
                                                                        }
                                                                        alt="중요"
                                                                        style={{
                                                                            maxWidth:
                                                                                "16px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            width: "16px",
                                                                        }}
                                                                    ></div>
                                                                )}

                                                                <div
                                                                    className="post-list-market-type"
                                                                    style={
                                                                        market.marketType ===
                                                                        "판매"
                                                                            ? {
                                                                                  paddingLeft:
                                                                                      "16px",
                                                                                  color: "red",
                                                                              }
                                                                            : market.marketType ===
                                                                              "구매"
                                                                            ? {
                                                                                  paddingLeft:
                                                                                      "16px",
                                                                                  color: "orange",
                                                                              }
                                                                            : {
                                                                                  paddingLeft:
                                                                                      "16px",
                                                                                  color: "gray",
                                                                              }
                                                                    }
                                                                >
                                                                    {
                                                                        market.marketType
                                                                    }
                                                                </div>

                                                                <div
                                                                    className="post-list-main-market-title"
                                                                    style={{
                                                                        paddingLeft:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    {
                                                                        market.title
                                                                    }
                                                                </div>
                                                                <div className="post-list-main-createdAt">
                                                                    {getRealDate(
                                                                        market.createdAt
                                                                    )}
                                                                </div>
                                                                <div className="post-list-main-author">
                                                                    {
                                                                        market.author
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        ) : (
                                            <div className="post-list-main">
                                                {markets
                                                    // .sort((a, b) => {
                                                    //     return b.createdAt - a.createdAt;
                                                    // })

                                                    .slice(
                                                        offset,
                                                        offset + limit
                                                    )
                                                    .map((market) => {
                                                        return (
                                                            <div
                                                                className="post-list-main-element"
                                                                id={
                                                                    market.isImportant
                                                                        ? "important"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/market_detail/${market.id}`
                                                                    )
                                                                }
                                                            >
                                                                {market.isImportant ? (
                                                                    <img
                                                                        src={
                                                                            important
                                                                        }
                                                                        alt="중요"
                                                                        style={{
                                                                            maxWidth:
                                                                                "16px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            width: "16px",
                                                                        }}
                                                                    ></div>
                                                                )}

                                                                <div
                                                                    className="post-list-market-type"
                                                                    style={
                                                                        market.marketType ===
                                                                        "판매"
                                                                            ? {
                                                                                  paddingLeft:
                                                                                      "16px",
                                                                                  color: "red",
                                                                              }
                                                                            : market.marketType ===
                                                                              "구매"
                                                                            ? {
                                                                                  paddingLeft:
                                                                                      "16px",
                                                                                  color: "orange",
                                                                              }
                                                                            : {
                                                                                  paddingLeft:
                                                                                      "16px",
                                                                                  color: "gray",
                                                                              }
                                                                    }
                                                                >
                                                                    {
                                                                        market.marketType
                                                                    }
                                                                </div>

                                                                <div
                                                                    className="post-list-main-market-title"
                                                                    style={{
                                                                        paddingLeft:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    {
                                                                        market.title
                                                                    }
                                                                </div>
                                                                <div className="post-list-main-createdAt">
                                                                    {getRealDate(
                                                                        market.createdAt
                                                                    )}
                                                                </div>
                                                                <div className="post-list-main-author">
                                                                    {
                                                                        market.author
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {/* 여기서부터 갤러리로 보여지는 부분입니다  */}
                                        {/* 이 밑은 검색시에 보여지는 부분입니다  */}
                                        {isFiltered ? (
                                            <div className="post-gallery-main">
                                                {filteredMarkets
                                                    // .sort((a, b) => {
                                                    //     return b.createdAt - a.createdAt;
                                                    // })
                                                    .slice(
                                                        offset,
                                                        offset + limit
                                                    )
                                                    .map((market) => {
                                                        return (
                                                            <div
                                                                className="market-gallery-element"
                                                                id={
                                                                    market.isImportant
                                                                        ? "important"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/market_detail/${market.id}`
                                                                    )
                                                                }
                                                            >
                                                                {market.photoUrl
                                                                    .length >
                                                                0 ? (
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: `url(${market.photoUrl[0]})`,
                                                                            backgroundSize:
                                                                                "cover",
                                                                        }}
                                                                        className="market-gallery-element-photo-section"
                                                                    ></div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: `url(${marketDefaultImg})`,
                                                                            backgroundSize:
                                                                                "cover",
                                                                        }}
                                                                        className="market-gallery-element-photo-section"
                                                                    ></div>
                                                                )}

                                                                <div className="market-gallery-element-text-section">
                                                                    {market.isImportant ? (
                                                                        <img
                                                                            src={
                                                                                important
                                                                            }
                                                                            alt="중요"
                                                                            style={{
                                                                                maxWidth:
                                                                                    "16px",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                width: "16px",
                                                                            }}
                                                                        ></div>
                                                                    )}

                                                                    <div
                                                                        className="post-list-market-type"
                                                                        style={
                                                                            market.marketType ===
                                                                            "판매"
                                                                                ? {
                                                                                      color: "red",
                                                                                  }
                                                                                : market.marketType ===
                                                                                  "구매"
                                                                                ? {
                                                                                      color: "orange",
                                                                                  }
                                                                                : {
                                                                                      color: "gray",
                                                                                  }
                                                                        }
                                                                    >
                                                                        {
                                                                            market.marketType
                                                                        }
                                                                    </div>

                                                                    <div
                                                                        className="post-list-main-market-title"
                                                                        style={{
                                                                            paddingLeft:
                                                                                "16px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            market.title
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        ) : (
                                            <div className="post-gallery-main">
                                                {/* 이 밑은 검색 안했을 때 보여지는 부분입니다  */}

                                                {markets
                                                    // .sort((a, b) => {
                                                    //     return b.createdAt - a.createdAt;
                                                    // })

                                                    .slice(
                                                        offset,
                                                        offset + limit
                                                    )
                                                    .map((market) => {
                                                        return (
                                                            <div
                                                                className="market-gallery-element"
                                                                id={
                                                                    market.isImportant
                                                                        ? "important"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/market_detail/${market.id}`
                                                                    )
                                                                }
                                                            >
                                                                {market.photoUrl
                                                                    .length >
                                                                0 ? (
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: `url(${market.photoUrl[0]})`,
                                                                            backgroundSize:
                                                                                "cover",
                                                                        }}
                                                                        className="market-gallery-element-photo-section"
                                                                    ></div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: `url(${marketDefaultImg})`,
                                                                            backgroundSize:
                                                                                "cover",
                                                                        }}
                                                                        className="market-gallery-element-photo-section"
                                                                    ></div>
                                                                )}

                                                                <div className="market-gallery-element-text-section">
                                                                    {market.isImportant ? (
                                                                        <img
                                                                            src={
                                                                                important
                                                                            }
                                                                            alt="중요"
                                                                            style={{
                                                                                maxWidth:
                                                                                    "16px",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                width: "16px",
                                                                            }}
                                                                        ></div>
                                                                    )}

                                                                    <div
                                                                        className="post-list-market-type"
                                                                        style={
                                                                            market.marketType ===
                                                                            "판매"
                                                                                ? {
                                                                                      color: "red",
                                                                                  }
                                                                                : market.marketType ===
                                                                                  "구매"
                                                                                ? {
                                                                                      color: "orange",
                                                                                  }
                                                                                : {
                                                                                      color: "gray",
                                                                                  }
                                                                        }
                                                                    >
                                                                        {
                                                                            market.marketType
                                                                        }
                                                                    </div>

                                                                    <div
                                                                        className="post-list-main-market-title"
                                                                        style={{
                                                                            paddingLeft:
                                                                                "16px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            market.title
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                    </>
                                )}

                                {/* 여기가 끝입니다!! */}

                                <div
                                    className="baseNav"
                                    style={{ display: "flex" }}
                                >
                                    <div
                                        className="post-list-pagination"
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            "flexDirection": "row-reverse",
                                            marginRight: "20%",
                                        }}
                                    >
                                        {isFiltered ? 
                                        <Pagination
                                            total={filteredMarkets.length}
                                            limit={limit}
                                            page={page}
                                            setPage={setPage}
                                        /> : 
                                        <Pagination
                                            total={markets.length}
                                            limit={limit}
                                            page={page}
                                            setPage={setPage} 
                                        />}
                                    </div>
                                    <div></div>
                                    <div
                                        style={{
                                            display: "flex",
                                            "align-items": "center",
                                        }}
                                    >
                                        <form
                                            className="searchForm"
                                            style={{
                                                border: "1px solid black",
                                                width: "240px",
                                                height: "32px",
                                            }}
                                        >
                                            <input
                                                className="text"
                                                name="searchBtn"
                                                placeholder="검색어를 입력하세요"
                                                autoComplete="off"
                                                ref={searchText}
                                                style={{
                                                    width: "200px",
                                                    height: "27px",
                                                    border: "none",
                                                    paddingLeft: "4px",
                                                }}
                                                onKeyDown={onSearchEnter}
                                            />
                                            <img
                                                src={searchIcon}
                                                alt="검색"
                                                style={{
                                                    maxWidth: "5.5%",
                                                    marginLeft: "1%",
                                                    "paddingTop": "8px",
                                                }}
                                                onClick={onSearch}
                                            />
                                        </form>
                                        {isLoggedIn ? (
                                            <button
                                                onClick={() =>
                                                    history.push(
                                                        "create_market"
                                                    )
                                                }
                                                style={{
                                                    width: "100px",
                                                    height: "30px",
                                                    marginLeft: "3%",
                                                    backgroundColor: "#1D333E",
                                                    color: "white",
                                                }}
                                            >
                                                글쓰기
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Market;
