import Layout from "../components/Layout";
import "../styles/postPageBase.scss";
import "../styles/CreatePostPage.scss";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { dbService, storageService } from "fbase";

const UpdateChild = ({
    isLoggedIn,
    userObj,
    editId,
    editTitle,
    editContent,
    editPhotoUrl,
}) => {
    const history = useHistory();
    const [title, setTitle] = useState(editTitle);
    const [content, setContent] = useState(editContent);
    const [photos, setPhotos] = useState(editPhotoUrl);
    const [init, setInit] = useState(false);
    const [fileName, setFileName] = useState("첨부파일");

    const onChange = (event) => {
        const {
            target: { name, value },
        } = event;
        if (name === "title") {
            setTitle(value);
        } else if (name === "content") {
            setContent(value);
        }
    };

    const onClearPhoto = (photo) => {
        setPhotos(photos.filter((e) => e !== photo.target.value));
    };

    const onFileChange = async (event) => {
        const {
            target: { files },
        } = event;

        if (files.length > 0) {
            setFileName(files[files.length - 1].name);
        } else {
            setFileName("첨부파일");
        }

        let fileURLs = [...photos];

        for (let i = 0; i < files.length; i++) {
            const theFile = files[i];
            const reader = new FileReader();

            reader.onload = () => {
                fileURLs.push(reader.result);
                setPhotos([...fileURLs]);
            };
            reader.readAsDataURL(theFile);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        await dbService.doc(`아동가족학과/${editId}`).update({
            title,
            content,
            photoUrl: photos,
        });

        

        setTitle("");
        setContent("");
        setPhotos([]);

        history.push("/child");
    };

    return (
        <>
            <div className="title-banner">아동가족학과</div>
            <div className="post-wrap">
                <div className="post-contents-wrap">
                    <div className="post-name">아동가족학과</div>
                    <form onSubmit={onSubmit}>
                        <div className="create-post-title">
                            <input
                                className="create-post-title-input"
                                value={title}
                                type="text"
                                name="title"
                                onChange={onChange}
                            />
                        </div>
                        <div className="create-post-content">
                            <textarea
                                className="create-post-content-input"
                                value={content}
                                type="text"
                                name="content"
                                onChange={onChange}
                            />
                        </div>

                        <div className="create-post-uploads">
                            <input
                                className="upload-name"
                                value={fileName}
                                placeholder={fileName}
                            />
                            <label for="upload-file">사진 찾기</label>
                            <input
                                id="upload-file"
                                type="file"
                                accept="image/*"
                                onChange={onFileChange}
                                multiple
                            />
                        </div>
                        <div className="create-post-submit">
                            <input
                                type="submit"
                                value="수정하기"
                                style={{
                                    width: "100px",
                                    height: "30px",
                                    backgroundColor: "#1D333E",
                                    color: "white",
                                }}
                            />
                        </div>
                    </form>

                    {photos.length !== 0 &&
                        photos.map((e) => (
                            <div className="create-post-photo-section">
                                <div className="create-post-photo-section-photo-box">
                                    <img
                                        className="create-post-photo-section-real-photo"
                                        src={e}
                                    ></img>
                                </div>
                                <div className="create-post-photo-section-photo-delete">
                                    <button
                                        onClick={(e) => onClearPhoto(e)}
                                        value={e}
                                        style={{
                                            display: "inline-block",
                                            width: "100px",
                                            height: "30px",
                                            color: "#fff",
                                            verticalAlign: "middle",
                                            backgroundColor: "#999999",
                                            cursor: "pointer",
                                            marginTop: "1%",
                                            border: "0px",
                                        }}
                                    >
                                        사진 삭제
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default UpdateChild;
